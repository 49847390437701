export const enhancer = (el) => {
  const audio = new Audio(el.getAttribute('data-audio'));
  el.addEventListener("click", () => {
    if (el.classList.contains('site-header__extra-audio--play')) {
      audio.pause();
      audio.currentTime = 0;
      el.classList.remove('site-header__extra-audio--play');
    } else {
      audio.play();
      el.classList.add('site-header__extra-audio--play');
    }
  });
};
